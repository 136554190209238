import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/credentials/login/Login";
import Register from "./components/credentials/register/Register";
import VerifyEmail from "./components/credentials/verify/VerifyEmail";
import UserOnboardingService from "./components/onboarding/UserOnboardingService";
import VehicleOnboardingService from "./components/onboarding/VehicleOnboardingService";
import CorporateDashboard from "./components/dashboard/corporate/CorporateDashboard";
import IndividualDashboard from "./components/dashboard/individual/IndividualDashboard";
import CustomerDashboard from "./components/dashboard/customer/CustomerDashboard";
import CustomerRequest from "./components/customerRequest/CustomerRequest";
import AddCorporate from "./components/dashboard/corporate/addCorporate/AddCorporate";
import InvitedRegister from "./components/credentials/register/InvitedRegister";
import ResendVerification from "./components/credentials/verify/ResendVerification";
import DriverDashboard from "./components/dashboard/driver/DriverDashboard";
import Availability from "./components/dashboard/driver/Availability";
import UpdateAddress from "./components/credentials/address/UpdateAddress";
import UserProfile from "./components/profile/UserProfile";
import ResetPassword from "./components/credentials/resetPassword/ResetPassword";
import SendLink from "./components/credentials/resetPassword/SendLink";
import VerifyEmailPasswordReset from "./components/credentials/verify/VerifyEmailPasswordReset";
import AccountDeletion from "./components/credentials/account-delete/AccountDeletion";


function App() {
  return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" exact element={<Login />} />
            {/* <Route path="/dashboard" element={<PrivateRoute element={ <Dashboard />} />}/> */}
            <Route path="/corporate-dashboard" element={<CorporateDashboard />} />
            <Route path="/individual-dashboard" element={<IndividualDashboard />} />
            <Route path="/customer-dashboard" element={<CustomerDashboard />} />
            <Route path="/driver-dashboard" element={<DriverDashboard/>} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/verify" element={<VerifyEmail />} />
            <Route path="/VerifyEmailPasswordReset" element={<VerifyEmailPasswordReset />} />
            <Route path="/available" element={<Availability />} />
            <Route path="/vehicle-service" element={<VehicleOnboardingService />} />
            <Route path="/user-service" element={<UserOnboardingService />} />
            <Route path="/delete-account" element={<AccountDeletion />}  />
            <Route path="/customer" element={<CustomerRequest />} />
            <Route path="create-corporate" element={<AddCorporate />} />
            <Route path="/invited" element={<InvitedRegister />} />
            <Route path="/address" element={<UpdateAddress />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/reset-link" element={<SendLink />} />
            <Route path="/resend-verification" element={<ResendVerification />} />
            <Route path="*" element={<h1>404 Not Found</h1>} />
          </Routes>
        </BrowserRouter>
      </div>
  );
}

export default App;
