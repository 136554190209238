import React, { useState } from 'react'
import './AccountDeletion.css'
import { deleteUserAccount } from '../../service/maxon.service';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Input } from 'antd';
const { TextArea } = Input;

const reasons = [
    "I am no longer using the service",
    "The service is too expensive",
    "I found a better alternative",
    "I had issues with customer support",
    "The platform does not meet my needs",
    "I’m concerned about my data privacy",
    "The service is not available in my location",
    // "I don’t understand how to use the service",
    "The service does not provide the features I need",
    "I prefer not to say",
    "Other",
];

const AccountDeletion = () => {
    const [selectedReasons, setSelectedReasons] = useState([]);
    const [showOtherReason, setShowOtherReason] = useState(false);
    const [otherReasonText, setOtherReasonText] = useState("");
    const navigate = useNavigate();

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedReasons((prev) => [...prev, value]);
            if (value === "Other") {
                setShowOtherReason(true);
            }
        } else {
            setSelectedReasons((prev) => prev.filter((reason) => reason !== value));
            if (value === "Other") {
                setShowOtherReason(false);
                setOtherReasonText(""); // Clear text if "Other" is unchecked
            }
        }
    };

    const handleDeleteAccount = (event) => {
        event.preventDefault();
        if (selectedReasons.length === 0) {
            notification.error({
                message: "Please select at least one reason before proceeding.",
            });
            return;
        }
        const reasonsToSend = selectedReasons.includes("Other")
            ? [...selectedReasons.filter((reason) => reason !== "Other"), otherReasonText]
            : selectedReasons;

        const reasonsString = reasonsToSend.join('.; '); // Join reasons with a delimiter

        // Call backend API
        deleteUserAccount({ reason: reasonsString }).then(() => {
            notification.success({
                message: "Account deleted",
                description: "Your account has been successfully deleted.",
            });
            navigate("/login");
        })
            .catch((error) => {
                notification.error({
                    message: "Failed to delete account, please try again",
                    description: error.message,
                });
            });
    };

    return (
        <div className="account-deletion-feedback">
            <h2>Delete account</h2>
            <p>
                We're sorry to see you leave 😢. Are you sure you want to delete your account? Once confirmed, all your data will be permanently removed.
            </p>
            <form onSubmit={handleDeleteAccount}>
                {reasons.map((reason, index) => (
                    <div key={index} className="checkbox-container">
                        <label>
                            <input
                                type="checkbox"
                                value={reason}
                                onChange={handleCheckboxChange}
                            />
                            {reason}
                        </label>
                    </div>
                ))}
                {showOtherReason && (
                    <TextArea
                        value={otherReasonText}
                        maxLength={100}
                        onChange={(e) => setOtherReasonText(e.target.value)}
                        placeholder="Please specify your reason"
                        style={{
                            height: 50,
                            resize: 'vertical',
                            marginBottom: 10,
                            width: '90%',
                        }}
                        required={showOtherReason}
                    />
                )}
                <button type="submit" className="delete-button">
                    Delete Account
                </button>
            </form>
        </div>
    );
}

export default AccountDeletion