import React, { useEffect, useState } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Person } from "@mui/icons-material";
import { mainListItems } from "../customer/ListItems";
import { Menu } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import Copyright from "../../copyright/Copyright";
import { getCustomerRideHistory } from "../../service/dashboard.service";
import UserRidesHistory from "../../history/UserRidesHistory";
import { useNavigate } from "react-router-dom";


const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: '#024959',
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    backgroundColor: '#024959',
    color: '#fff',
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));


const defaultTheme = createTheme();

const CustomerDashboard = () => {
  const [open, setOpen] = React.useState(true);
  const [onboardedTruck, setOnboardedTruck] = useState(0);
  const [onboardedCars, setOnboardedCars] = useState(0);
  const [onboardedDrivers, setOnboardedDrivers] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const openAnchor = Boolean(anchorEl);
  const username = localStorage.getItem('username');
  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
  }

  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCustomerRideHistory();
        const rides = response.data;

      } catch (error) {
        console.error('Error fetching onboarded vehicles:', error);
      }
    };
    fetchData();
  }, []);

  const userProfile = () => {
    navigate('/profile');
  }
  const handleDelete = () => {
    navigate('/delete-account');
  }

  // useEffect(() => {
  //   const token = localStorage.getItem('token');
  //   const client = new Client({
  //     brokerURL: 'ws://localhost:8081/massitec',
  //     connectHeaders: {
  //       Authorization: `Bearer ${token}`,
  //     },

  //     onConnect: () => {
  //       console.log('Connected to the broker', client.connected)
  //       client.subscribe('/topic/customer-trip', message => {
  //         const msg = JSON.parse(message.body);
  //         console.log('Received: ', msg);

  //         if (msg === 'Finding driver for you, you will be notified when a driver accepts your request') {
  //           swal({
  //             title: 'Driver Found!',
  //             text: 'A driver has accepted your request. Please check your dashboard for more details.',
  //             icon: 'success',
  //             confirmButtonText: 'OK',
  //           });
  //         } else if (msg === 'No driver found for your request') {
  //           swal({
  //             title: 'No Driver Found!',
  //             text: 'Sorry, no driver is available at the moment. Please try again later.',
  //             icon: 'error',
  //             confirmButtonText: 'OK',
  //           });
  //         }
  //       });
  //     },

  //     onStompError: (frame) => {
  //       console.log('Broker reported error: ' + frame.headers['message']);
  //       console.log('Additional details: ' + frame.body);
  //     },
  //   });

  //   client.activate();

  // }, []);


  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1, marginLeft: "1rem" }}
              >
                Maxon
              </Typography>
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="#r"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Dashboard
            </Typography>
            <div>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <Person />
                <Typography variant="h6" color="inherit" noWrap style={{ fontSize: '1rem', marginLeft: '0.5rem' }}>
                  {username}
                </Typography>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={openAnchor}
                onClose={handleClose}
              >
                <MenuItem onClick={userProfile}>Profile</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                <MenuItem onClick={handleDelete}>Delete Account</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer} sx={{ color: '#fff' }}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            {/* <Divider sx={{ my: 1 }} /> */}
            {/* {secondaryListItems} */}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: 'bold' }}>
                  My Rides
                </Typography>
                <UserRidesHistory title={'History of my Rides'} />
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>

    </ThemeProvider>
  );
}

export default CustomerDashboard;