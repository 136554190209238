import React from 'react'
import { Paper, Grid, Typography, useTheme, Box } from '@mui/material';

const Summary = ({ icon, bgStyles, number, title, description }) => {
    const theme = useTheme();
    return (
        <Paper
            sx={{
                p: 2,
                margin: 'auto',
                maxWidth: 280,
                flexGrow: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                minHeight: 110,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                boxShadow: 2,
            }}
        >
            <Grid
                container
                spacing={2}
                alignItems="center"
            >
                {/* Icon Section */}
                <Grid item>
                    <Box
                        sx={{
                            backgroundColor: bgStyles,
                            padding: '8px',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 50,
                            height: 50,
                        }}
                    >
                        {icon}
                    </Box>
                </Grid>
                {/* Content Section */}
                <Grid item xs>
                    <Typography
                        variant="h6"
                        sx={{ fontWeight: 600, marginBottom: 1 }}
                    >
                        {number} {title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {description}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}


export default Summary