import React, { useEffect, useState } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Person } from "@mui/icons-material";
import { mainListItems } from "../individual/ListItems";
import { Menu, Skeleton } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import Summary from "../../summary/Summary";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PeopleIcon from '@mui/icons-material/People';
import Copyright from "../../copyright/Copyright";
import HistoryVehicleOnboarding from "../../history/HistoryVehicleOnboarding";
import { getOnboardedVehicles } from "../../service/onboarding.service";
import { getActiveVehicles, getAllOnboardedDrivers, getIdleVehicles, getInactiveVehicles, getOnRouteVehicles } from "../../service/dashboard.service";
import WarningIcon from '@mui/icons-material/Warning';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VehiclesOverview from "../../overview/VehiclesOverview";
import Statistics from "../../statistics/Statistics";
import HistoryUserOnboarding from "../../history/HistoryUserOnboarding";
import { useNavigate } from "react-router-dom";


const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: '#024959',
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    backgroundColor: '#024959',
    color: '#fff',
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));


const defaultTheme = createTheme();

const IndividualDashboard = () => {
  const [open, setOpen] = React.useState(true);
  const [onboardedTruck, setOnboardedTruck] = useState(0);
  const [onboardedCars, setOnboardedCars] = useState(0);
  const [onboardedDrivers, setOnboardedDrivers] = useState(0);
  const [onRouteVehicles, setOnRouteVehicles] = useState(0);
  const [activeVehicles, setActiveVehicles] = useState(0);
  const [inactiveVehicles, setInactiveVehicles] = useState(0);
  const [idleVehicles, setIdleVehicles] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const openAnchor = Boolean(anchorEl);
  const navigate = useNavigate();


  const username = localStorage.getItem('username');
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const userProfile = () => {
    navigate('/profile');
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
  }

  useEffect(() => {
    const fetchData = async () => {
      const result = await getOnRouteVehicles();
      setOnRouteVehicles(result.data.length);

      const activeVehicles = await getActiveVehicles();
      setActiveVehicles(activeVehicles.data.length);

      const inactiveVehicles = await getInactiveVehicles();
      setInactiveVehicles(inactiveVehicles.data.length);

      const idleVehicles = await getIdleVehicles();
      setIdleVehicles(idleVehicles.data.length);
    };
    fetchData();
  }, []);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getOnboardedVehicles();
        const vehicles = response.data;

        // Count trucks and cars
        const trucks = vehicles.filter(vehicle => vehicle.evehicleType === 'TRUCK');
        const cars = vehicles.filter(vehicle => vehicle.evehicleType === 'CAR');

        setOnboardedTruck(trucks.length);
        setOnboardedCars(cars.length);

        const drivers = await getAllOnboardedDrivers();
        setOnboardedDrivers(drivers.data.length);

      } catch (error) {
        console.error('Error fetching onboarded vehicles:', error);
      }
    };
    fetchData();
  }, []);


  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1, marginLeft: "1rem" }}
              >
                Maxon
              </Typography>
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="#r"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Dashboard
            </Typography>
            <div>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <Person />
                <Typography variant="h6" color="inherit" noWrap style={{fontSize: '1rem', marginLeft: '0.5rem'}}>
                  {username}
                </Typography>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={openAnchor}
                onClose={handleClose}
              >
                <MenuItem onClick={userProfile}>Profile</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer} sx={{ color: '#fff' }}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            {/* <Divider sx={{ my: 1 }} /> */}
            {/* {secondaryListItems} */}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={1}>
              {/* start summary  */}
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Summary 
                  icon={<LocalShippingIcon style={{color: '#30302e', fontSize: {xs: 24, md: 40}}}/>}
                  bgStyles={'#e2f4d7'}
                  number={onRouteVehicles}
                  title={'On Route'}
                  description={'Total number of on route vehicles'}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Summary 
                  icon={<LocalShippingIcon style={{color: '#30302e', fontSize: {xs: 24, md: 40}}} />}
                  bgStyles={'#e2f4d7'}
                  number={activeVehicles}
                  title={'Active'}
                  description={'Total number of active vehicles'}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Summary 
                  icon={<WarningIcon  style={{color: '#efa03d', fontSize: {xs: 24, md: 40}}}/>}
                  bgStyles={'#e9f2f6'}
                  number={inactiveVehicles}
                  title={'Offline'}
                  description={'Total number of oflline vehicles'}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Summary 
                  icon={<AccessTimeIcon style={{ color: '#ec428f', fontSize: {xs: 24, md: 40}}} />}
                  bgStyles={'#e9f2f6'}
                  number={idleVehicles}
                  title={'Idle'}
                  description={'Total number of Idle vehicles'}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} sx={{ marginBottom: '1.5rem' }}>
                <Summary
                  icon={<LocalShippingIcon style={{ color: '#30302e', fontSize: {xs: 24, md: 40} }} />}
                  bgStyles={'#D5ECF6'}
                  number={onboardedCars}
                  title={'Cars'}
                  description={'Total number of onboarded cars'}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} sx={{ marginBottom: '1.5rem' }}>
                <Summary
                  icon={<LocalShippingIcon style={{ color: '#30302e', fontSize: {xs: 24, md: 40} }} />}
                  bgStyles={'#D5ECF6'}
                  number={onboardedTruck}
                  title={'Trucks'}
                  description={'Total number of onboarded truck'}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} sx={{ marginBottom: '1.5rem' }}>
                <Summary
                  icon={<PeopleIcon style={{ color: '#30302e', fontSize: {xs: 24, md: 40} }} />}
                  bgStyles={'#e0d8f7'}
                  number={onboardedDrivers}
                  title={'Drivers'}
                  description={'Total number of onboarded drivers'}
                />
              </Grid>
              {/* end summary */}
              {/* overview  */}
              <Grid item xs={12} sm={6} md={4} lg={6} sx={{marginBottom: '1rem'}}>
                <VehiclesOverview />
              </Grid>
              {/* statistics */}
              <Grid item xs={12} sm={6} md={4} lg={6} sx={{marginBottom: '1rem'}}>
                <Statistics />
              </Grid>
              {/* <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
                <HistoryDriverOnboarding />
              </Grid> */}
              <Grid item xs={12} sx={{ marginBottom: '1rem' }} style={{ textAlign: 'left' }}>
                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: 'bold' }}>
                  History of User Onboarding
                </Typography>
                <HistoryUserOnboarding />
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: 'bold' }}>
                  History of Vehicle Onboarding
                </Typography>
                <HistoryVehicleOnboarding title={'History of Vehicle Onboarding'} />
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default IndividualDashboard;