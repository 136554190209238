import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { userOnboarding } from "../service/onboarding.service";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";


const Copyright = (props) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="#">
        Massitec Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const defaultTheme = createTheme();

const UserOnboardingService = () => {
  const [email, setEmail] = useState('')
  const [EUserType, setEUserType] = useState('');
  const [ERole, setERole] = useState('')
  const navigate = useNavigate();


  const userService = (event) => {
    event.preventDefault()
    const corporateId = localStorage.getItem('corporateId');

    userOnboarding(corporateId, {
      email,
      EUserType,
      ERole,
    })
      .then((response) => {
        if(response.status === 200) {
          notification.success({
            message: 'User Onboarding',
            description: `User has been onboarded successfully`,
            });
            navigate("/corporate-dashboard", { replace: true });
        }
        else if(response.status === 400) {
          notification.error({
            message: 'Access Denied',
            description: 'Your corporate verification is incomplete.\n ' +
                'Please visit our office to complete the verification process \n' +
                ' and gain access to onboard users',
          });
        }
      })
      .catch((error) => {
        notification.error({
          message: 'Failed to send email to the user',
          description: `Failed to Onboarding User`,
        });
      });
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: '#fff',
            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
            padding: 2,
            borderRadius: 1,
            width: '35rem',
            height: '20rem',
        }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
             Onboard User
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={userService}
            sx={{ mt: 5 }}
          >
            <Grid container spacing={2.5}>
              <Grid item xs={12} sx={{
                width: 500,
                maxWidth: '100%',
              }}>
                <TextField
                  autoComplete="email"
                  name="email"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  autoFocus
                  size="small"
                  onChange={(event) => setEmail(event.target.value)}
                />
              </Grid>
              <Grid item spacing={1.5} xs={12} sm={6}>
                <FormControl sx={{
                  width: 500,
                  maxWidth: '100%'
                }} size="small">
                  <InputLabel id="demo-select-small-label">
                    User Type
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    required
                    value={EUserType}
                    label="User Type"
                    sx={{ textAlign: "left" }}
                    onChange={(event) => setEUserType(event.target.value)}
                  >
                    <MenuItem value={'CORPORATE'}>Employee</MenuItem>
                    <MenuItem value={'DRIVER'}>Driver</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item spacing={1.5} xs={12} sm={6}>
                <FormControl sx={{
                  width: 500,
                  maxWidth: '100%'
                }} size="small">
                  <InputLabel id="demo-select-small-label">
                    Role
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    required
                    value={ERole}
                    label="Role"
                    sx={{ textAlign: "left" }}
                    onChange={(event) => setERole(event.target.value)}
                  >
                    <MenuItem value={'ADMIN'}>Admin</MenuItem>
                    <MenuItem value={'USER'}>User</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
              <Button type="cancel" fullWidth variant="contained" sx={{ mt: 3, mb: 2, width: "30%" }}
                onClick={() => navigate("/corporate-dashboard", { replace: true }) }
              >
                Cancel
              </Button>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, width: "30%" }}
              >
                Submit
              </Button>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
};

export default UserOnboardingService;
